<template>
  <div class="width-full">
    <v-tabs
      color="red"
      v-model="tab"
      centered
      grow
      class="red "
    >
      <v-tab width="100%" class="red lighten-5 mr-10">Основные задачи и порядок работы ПТК:</v-tab>
      <v-tab class="red lighten-5">Противопожарные мероприятия ПТК</v-tab>
    </v-tabs>
    <v-tabs-items
    v-model="tab"
    >
      <v-tab-item
      vertical
      >
        <v-card flat>
          <v-container fluid class="px-10">
            <v-row class="pt-2">
              <v-col cols="6">
                <div class="d-flex align-center">
                  <v-img
                    lazy-src="img/material/match.svg"
                    max-height="100%"
                    max-width="8%"
                    contain
                    src="img/material/match.svg"
                  >
                  </v-img>
                  <v-card-text class="subtitle-1 font-weight-light ml-3">
                    Выявление пожароопасных нарушений и

                    <v-tooltip color="red" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          color="red"
                          dark
                          v-bind="attrs"
                          v-on="on"
                          class="subtitle-1 font-weight-light blue lighten-4"
                        >
                          нарушений
                        </span>
                      </template>
                      <span>в технологических процессов производства, в работе агрегатов, установок, лабораторий, мастерских, на складах, базах и т.п.</span>
                    </v-tooltip>

                     (в технологических процессах производства, в рабочих агрегатах, установках, лабораторий, мастерских, на складах, базах и т.д.);
                  </v-card-text>
                </div>
              </v-col>
              <v-col cols="6">
                <div class="d-flex">
                  <v-img
                    lazy-src="img/material/contract.svg"
                    max-height="100%"
                    max-width="8%"
                    contain
                    src="img/material/contract.svg"
                  >
                  </v-img>
                  <v-card-text class="subtitle-1 font-weight-light ml-3">
                    Разработка мероприятий, направленных на устранение нарушений;
                  </v-card-text>
                </div>
              </v-col>
              <v-col cols="6">
                <div class="d-flex">
                  <v-img
                    lazy-src="img/material/reunion.svg"
                    max-height="100%"
                    max-width="8%"
                    contain
                    src="img/material/reunion.svg"
                  >
                  </v-img>
                  <v-card-text class="subtitle-1 font-weight-light ml-3">
                    Проведение массово-разъяснительной работы среди
                    <v-tooltip color="red" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          color="red"
                          dark
                          v-bind="attrs"
                          v-on="on"
                          class="subtitle-1 font-weight-light blue lighten-4"
                        >
                          сотрудников
                        </span>
                      </template>
                      <span>рабочих, служащих, ИТР</span>
                    </v-tooltip>
                     по вопросам соблюдения противопожарного режима;
                  </v-card-text>
                </div>
              </v-col>
              <v-col cols="6">
                <div class="d-flex">
                  <v-img
                    lazy-src="img/material/fire-shield.svg"
                    max-height="100%"
                    max-width="8%"
                    contain
                    src="img/material/fire-shield.svg"
                  >
                  </v-img>
                  <v-card-text class="subtitle-1 font-weight-light ml-3">
                    Cодействие пожарной охране в организации и проведении пожарно-профилактической работы и установлении строгого противопожарного режима в производственных цехах, складах, административных  и жилых зданиях;
                  </v-card-text>
                </div>
              </v-col>
              <v-col cols="6">
                <div class="d-flex">
                  <v-img
                    lazy-src="img/material/idea.svg"
                    max-height="100%"
                    max-width="8%"
                    contain
                    src="img/material/idea.svg"
                  >
                  </v-img>
                  <v-card-text class="subtitle-1 font-weight-light ml-3">
                    Организация рационализаторской и изобретательской работы по вопросам ПБ.
                  </v-card-text>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-container fluid class="px-10">
          <v-row>
            <v-col cols="6">
              <v-card flat>
                <v-card-text class="title font-weight-light pa-0 pb-4">
                  <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                  Проводить осмотры:
                  <p class="pl-10">- детальный осмотр всех
                    <v-tooltip color="red" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          color="red"
                          dark
                          v-bind="attrs"
                          v-on="on"
                          class="title font-weight-light blue lighten-4"
                        >
                          производственных зданий
                        </span>
                      </template>
                      <span>баз, складов, лабораторий и других служебных помещений предприятия</span>
                    </v-tooltip>

                  <span class="red white--text ml-1"><strong>не реже 2 - 4 раз</strong></span> в год с целью выявления пожароопасных недочетов;
                  </p>
                  <p class="pa-0 mb-0 pl-10">- общественные осмотры противопожарного состояния цехов, складов предприятия и боеготовности
                    ДПД;
                  </p>
                </v-card-text>
                <v-card-text class="title font-weight-light pa-0 pb-4">
                  <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                  Проверять выполнение противопожарных мероприятий, предложенных государственным пожарным надзором;
                </v-card-text>
                <v-card-text class="title font-weight-light pa-0 pb-4">
                  <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                  Намечать способы устранения выявленых недочетов и устанавливать сроки выполнения противопожарных мероприятий;
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-card flat>
                <v-card-text class="title font-weight-light pa-0 pb-4">
                  <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                  Проводить:
                  <p class="pl-10">- беседы и лекции с сотрудниками на противопожарные темы;
                  </p>
                  <p class="pl-10">- пожарно-технические конференции с участием специалистов пожарной охраны, научно-технических работников, актива трудящихся по вопросам ПБ;
                    ДПД;
                  </p>
                </v-card-text>
                <v-card-text class="title font-weight-light pa-0 pb-4">
                  <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                  Ставить вопросы о противопожарном состоянии предприятия на обсуждение руководству, а также производственных совещаний;
                </v-card-text>

                <v-card-text class="title font-weight-light pa-0">
                  <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                  Принимать активное участие в разработке совместно с администрацией инструкций, правил ПБ.
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
export default {
  data: () => ({
    tab: null
  })
}
</script>
