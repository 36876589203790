<template>
  <v-container>
    <v-row>
      <v-col cols='12'>
        <v-card-title >
          6. Создание пожарно-технических комиссий и добровольных пожарных дружин
        </v-card-title>
      </v-col>
      <v-col cols="12">
        <div class="d-flex">
          <v-img
            lazy-src="img/material/balance.svg"
            max-height="100%"
            max-width="10%"
            contain
            src="img/material/balance.svg"
          >
          </v-img>
          <v-card-text class="title font-weight-light ml-5">
            <span class="font-weight-bold">ФЗ №100 от 06.05.2011 "О добровольной пожарной охране"</span> устанавливает правовые основы создания и деятельности добровольной пожарной охраны, права и гарантии деятельности общественных объединений пожарной охраны и добровольных пожарных, регулирует
            <v-tooltip color="red" bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                  color="red"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  class="title font-weight-light blue lighten-4"
                >
                  отношения.
                </span>
              </template>
              <span>Отношения добровольной пожарной охраны с органами гос. Власти, органами местного самоуправления, организациями и гражданами РФ, иностранными гражданами и лицами без гражданства</span>
            </v-tooltip>
          </v-card-text>
        </div>
      </v-col>
      <v-col cols="12">
        <v-card-text class="title font-weight-light ml-5">
          Для привлечения сотрудников к работе по предупреждению и борьбе с пожарами на объектах могут создаваться пожарно-технические комиссии и добровольные пожарные формирования.
        </v-card-text>
      </v-col>
      <v-col cols="6">
        <v-card
        flat
        class="d-flex justify-center"
        >
          <v-img
            lazy-src="img/material/dpd.svg"
            max-height="100%"
            max-width="80%"
            contain
            src="img/material/dpd.svg"
          >
          </v-img>
        </v-card>
      </v-col>
      <v-col cols="6">
        <div class="mt-10 mb-10">
          <v-dialog
            v-model="dialog1"
            width="100%"
            class="pa-16"
          >
            <template v-slot:activator="{ on, attrs }">

              <v-btn
                color="red"
                dark
                class="pa-10"
                block
                outlined
                v-bind="attrs"
                v-on="on"

              >
                <v-icon size="40">mdi-account-group</v-icon>
                <v-spacer></v-spacer>
                  Пожарно-техническая коммисия (ПТК)
              </v-btn>
            </template>

            <v-card class="noselect">
              <v-card-title class="headline" dark>
                Пожарно-техническая комиссия (ПТК):
              </v-card-title>

              <v-card-title dark>
                ПТК назначается приказом руководителя и состоит из работников предприятия:
              </v-card-title>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                главного инженера (председатель);
              </v-card-text>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                начальника пожарной охраны;
              </v-card-text>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                ИТР-энергетика, технолога, механика, инженера по охране труда, специалиста по водоснабжению.
              </v-card-text>

              <v-card-text class="title font-weight-light">
                Повседневный контроль за выполнением противопожарных мероприятий возлагается на начальника пожарной пожарной охраны (ДПД) или заменяющего его лицо.
              </v-card-text>

              <v-card-text class="title font-weight-light">
                ПТК должна поддерживать постоянную связь с местными органами
                <v-tooltip color="red" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      color="red"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      class="title font-weight-light blue lighten-4"
                    >
                      госпожнадзора.
                    </span>
                  </template>
                  <span><span class="font-weight-bold"><strong>Государственной пожарный надзор</strong></span> специальный вид государственной надзорной деятельности, осуществлемый<br> должностыными лицами органов управления и подразделений Государственной противопожарной безопасности.<br> Госпожнадзор призван поддерживать высокий уровень пожарной безопасности в стране путем проведения обследований<br> и проверок противопожарного состояния населенныз пунктов, предприятий и организаций.</span>
                </v-tooltip>
              </v-card-text>

              <v-card-text class="title font-weight-light">
                <span class="red white--text pa-0.5"><strong>ПТК не имеет право отменять или изменять мероприятия</strong></span>, предусмотренные предписаниями госпожнадзора.
              </v-card-text>
              <Tab></Tab>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="dialog1 = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
        <div>
          <v-dialog
            v-model="dialog2"
            width="100%"
            class="pa-16"
          >
            <template v-slot:activator="{ on, attrs }">

              <v-btn
                color="red"
                dark
                class="pa-10"
                block
                v-bind="attrs"
                v-on="on"

              >
                <v-icon size="40">mdi-account-hard-hat</v-icon>
                <v-spacer></v-spacer>
                  Добровольные пожарные дружины (ДПД)
              </v-btn>
            </template>

            <v-card class="noselect">
              <v-card-title class="headline" dark>
                Добровольные пожарные дружины (ДПД)
              </v-card-title>
              <v-card class="ma-8 red lighten-5">
                <v-container fluid>
                  <v-row class="d-flex justify-center align-item">
                    <v-col cols="4">
                      <div class="d-flex justify-center align-item">
                        <v-img
                          lazy-src="img/material/fire-shield.svg"
                          max-height="100%"
                          max-width="20%"
                          contain
                          src="img/material/fire-shield.svg"
                        >
                        </v-img>
                        <v-card-text class="title font-weight-light">
                          Проведения мероприятий от пожаров
                        </v-card-text>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <div class="d-flex">
                        <v-img
                          lazy-src="img/material/document-shield.svg"
                          max-height="100%"
                          max-width="20%"
                          contain
                          src="img/material/document-shield.svg"
                        >
                        </v-img>
                        <v-card-text class="title font-weight-light">
                          Повседневного контроля и надзора за выполнением сотрудниками инструкций о мерах ПБ
                        </v-card-text>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <div class="d-flex">
                        <v-img
                          lazy-src="img/material/hose.svg"
                          max-height="100%"
                          max-width="20%"
                          contain
                          src="img/material/hose.svg"
                        >
                        </v-img>
                        <v-card-text class="title font-weight-light">
                          Надзора по содержанию технических средств противопожарной защиты.
                        </v-card-text>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
              <v-card-title dark>
                Лицо, ответственное за ПБ организации:
              </v-card-title>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                организует ДПД;
              </v-card-text>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                руководит деятельностью ДПД;
              </v-card-text>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                проводит массово-разъяснительную работу среди сотрудников.
              </v-card-text>

              <v-card-text class="title font-weight-light">
                Численный состав ДПД определяет руководитель объекта (цеха). <br>ДПД организуется на добровольных началах из числа рабочих, ИТР и служащих в возрасте <span class="red white--text pa-0.5"><strong>не моложе 18лет.</strong></span>
              </v-card-text>

              <v-card-title dark>
                Расходы и вознаграждения:
              </v-card-title>

              <v-card-text class="title font-weight-light">
                Все расходы по содержанию ДПД производится за счет цехов, на которых они организуются.<br>
                Оплата труда за время участия их в ликвидации пожара или аварии в рабочее время, а также за дежурство по пожарной охране вне рабочего времени осуществляется из расчета среднемесячного заработка на производстве.<br>
                Руководитель вправе выдавать лучшим членам ДПД за активную работу денежные премии и ценные подарки за счет средств фонда директора или других средств, предусмотренных на премирование.
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="dialog2 = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
        <Hint/>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Tab from '@/components/Page11/Tabs11.vue'
import Hint from '@/components/Hint.vue'
export default {
  components: {
    Hint,
    Tab
  },
  data: () => ({
    dialog1: null,
    dialog2: null
  })
}
</script>
